<template>
  <div class="login-container flex center">
    <div class="left" />
    <div class="right">
      <template v-if="[0, 1].includes(navCurIndex)">
        <div class="nav-bar">
          <span
            @click="navCurIndex = i"
            v-for="(v, i) in navList"
            :key="i"
            :class="{ active: i === navCurIndex }"
            class="nav-item not-select pointer">
            {{ v }}
          </span>
        </div>
        <div v-show="navCurIndex === 1" class="form-box">
          <div class="form-item">
            <input
              @keyup.enter="onLogin"
              v-model="params.phone"
              :class="{ check: rulesText.phone }"
              oninput="value=value.replace(/[^\d]/g,'')"
              ng-pattern="/[^a-zA-Z]/"
              maxlength="11"
              placeholder="请输入手机号" />
          </div>
          <div class="form-item">
            <input
              @keyup.enter="onLogin"
              v-model="params.code"
              :class="{ check: rulesText.code }"
              style="padding-right: 100px"
              placeholder="请输入手机验证码" />
            <div class="code-btn flex jc_e ai_c fs16">
              <t-btn
                @click="onGetCode('login')"
                v-show="!count"
                isText>
                发送验证码
              </t-btn>
              <span v-show="count">{{ count }}秒后</span>
            </div>
          </div>
          <div class="form-fd pt40">
            <div
              v-show="rulesText.phone || rulesText.code"
              class="check-text flex ai_c">
              <i class="icon" />
              {{ rulesText.phone || rulesText.code }}
            </div>
            <t-btn
              @click="onLogin"
              w="100%"
              h="55px"
              fs="18px">
              登录/注册
            </t-btn>
          </div>
        </div>
        <div v-show="navCurIndex === 0" class="wx-box">
          <wxlogin
            :appid="appid"
            :redirect_uri="redirect_uri"
            href="data:text/css;base64,Lm9sZC10ZW1wbGF0ZSB7CiAgICBkaXNwbGF5OiBmbGV4OwogICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgICBhbGlnbi1pdGVtczogY2VudGVyOwogICAgaGVpZ2h0OiAxMDAlOwogIH0KICAuaW1wb3dlckJveCB7CiAgICBsaW5lLWhlaWdodDogbm9ybWFsOwogIH0KICAuaW1wb3dlckJveCAud3JwX2NvZGUgewogICAgZm9udC1zaXplOiAwOwogIH0KICAuaW1wb3dlckJveCAudGl0bGUgewogICAgZGlzcGxheTogbm9uZTsKICB9CiAgLyogLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7CiAgICBkaXNwbGF5OiBub25lOwogIH0gKi8KICAuaW1wb3dlckJveCAucXJjb2RlIHsKICAgIGJvcmRlcjogbm9uZTsKICAgIHdpZHRoOiAxNDBweDsKICAgIGhlaWdodDogMTQwcHg7CiAgICBtYXJnaW46IDA7CiAgfQogIC5pbXBvd2VyQm94IC5zdGF0dXMgewogICAgcGFkZGluZy1ib3R0b206IDBweDsKICAgIHBhZGRpbmctdG9wOiAxNXB4OwogIH0KICAuaW1wb3dlckJveCAuc3RhdHVzIHAgewogICAgZm9udC1zaXplOiAxNHB4OwogICAgY29sb3I6ICM5OTk5OTk7CiAgfQ=="
            theme="black"
            scope="snsapi_login"
            class="wx-login"
            rel="external nofollow" >
          </wxlogin>
        </div>
        <div class="right-fd flex ai_c" style="justify-content:center">
          <img src="~@/assets/image/login/login_img_tips.png" style="width: 16px;height:16px;margin-right: 5px" />
          登录即代表同意
          <a href="https://storage.intoevidence.cn/agreement/user.html" target="_blank" style="color:#056BFD">《服务协议》</a>
          和
          <a href="https://storage.intoevidence.cn/agreement/privacy.html" target="_blank" style="color:#056BFD">《隐私政策》</a>
        </div>
        <!-- <div class="right-fd flex jc_b ai_c">
          <p class="flex ai_c fs16">
            <img src="~@/assets/image/login/login_img_tips.png" style="width: 16px;margin-right: 5px" />
            还没有账号？
          </p>
          <t-btn
            @click="$router.push('/buy')"
            type="danger"
            w="100px"
            h="36px">
            马上开通
          </t-btn>
        </div> -->
      </template>
      <template v-else-if="navCurIndex === 2">
        <p class="title">绑定手机号码</p>
        <div class="form-box">
          <div class="form-item">
            <input
              @keyup.enter="bindCommit"
              @blur="checkParams('phone')"
              v-model="params.phone"
              :class="{ check: rulesText.phone }"
              oninput="value=value.replace(/[^\d]/g,'')"
              ng-pattern="/[^a-zA-Z]/"
              maxlength="11"
              placeholder="请输入手机号" />
          </div>
          <div class="form-item">
            <input
              @keyup.enter="bindCommit"
              @blur="checkParams('code')"
              v-model="params.code"
              :class="{ check: rulesText.code }"
              style="padding-right: 100px"
              placeholder="请输入手机验证码" />
            <div class="code-btn flex jc_e ai_c fs16">
              <t-btn
                @click="onGetCode('bind')"
                v-show="!count"
                isText>
                发送验证码
              </t-btn>
              <span v-show="count">{{ count }}秒后</span>
            </div>
          </div>
          <div
            :class="rulesText.bind ? 'pt40' : 'pt70'"
            class="form-fd">
            <div
              v-show="rulesText.phone || rulesText.code"
              class="check-text flex ai_c">
              <i class="icon" />
              {{ rulesText.phone || rulesText.code }}
            </div>
            <t-btn
              @click="bindCommit"
              w="100%"
              h="55px"
              fs="18px">
              确认
            </t-btn>
          </div>
          <!-- <div v-show="rulesText.bind" class="form-fd pt60 pb40">
            <div
              class="check-text flex ai_c"
              style="top: 20px;">
              <i class="icon" />
              {{ rulesText.bind }}
            </div>
            <t-btn
              @click="$router.push('/buy')"
              type="danger"
              w="100%"
              h="55px"
              fs="18px">
              马上开通
            </t-btn>
          </div> -->
        </div>
      </template>
      <template v-else-if="navCurIndex === 3">
        <p class="title">选择所在组织</p>
        <el-scrollbar class="scroll-box">
          <template v-if="userInfo && userInfo.groups">
            <div @click="curTeam = v.groupId" v-for="v in userInfo.groups" :key="v.groupId" :class="{ lately: v.on && v.groupId === curTeam, active: v.groupId === curTeam }" class="cell-item pointer">
              <span>{{ v.groupName }}</span>
            </div>
          </template>
        </el-scrollbar>
        <div
          :class="{ shadow: userInfo.groups.length > 4 }"
          class="right-fd-scecond flex center">
          <t-btn
            @click="goSystem"
            w="400px"
            h="55px"
            fs="18px">
            进入系统
          </t-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import wxlogin from 'vue-wxlogin'
import message from '@/mixins/message'
import { isMobile } from '@/assets/javascript/util'

export default {
  mixins: [message],
  components: {
    wxlogin
  },
  data() {
    return {
      // navbar信息
      navList: ['微信登录','手机登录'],
      navCurIndex: 0, // 0手机登录，1微信登录，2绑定手机，3选择机构
      // 登录数据
      params: {
        phone: '',
        code: ''
      },
      // 验证码
      count: 0,
      countTimer: null,
      // 数据验证
      rulesText: {
        phone: '',
        code: '',
        bind: ''
      },
      // 二维码
      appid: process.env.VUE_APP_WECHAT_APPID,
      redirect_uri: encodeURIComponent(`http://${window.location.host}/login?cur=1`), 
    // redirect_uri: encodeURIComponent(`https://www-demo.intoevidence.cn/login?cur=1`),
      curTeam: null, 
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  methods: {
    ...mapMutations(['SETUSERINFO', 'SETCUR', 'SETAUTH']),
    // 倒计时
    countDown() {
      clearInterval(this.countTimer)
      this.count = 60
      
      this.countTimer = setInterval(() => {
        if (--this.count < 0) {
          clearInterval(this.countTimer)
          this.count = 0
        }
      }, 1000)
    },
    // 检验数据
    checkParams(key) {
      const { params } = this

      if (!params[key]) {
        this.$set(this.rulesText, key, key === 'phone' ? '手机号不能空哦！' : '验证码不能空哦！')
      } else if (key === 'phone' && !isMobile(params[key])) {
        this.$set(this.rulesText, key, '输入正确的手机号哦！')
      } else {
        this.$set(this.rulesText, key, '')
      }
    },
    // 点击获取验证码
    onGetCode(type) {
      this.checkParams('phone')

      if (this.rulesText.phone) {
        return
      }

      this.$api.system.getCode({
        type,
        phone: this.params.phone
      }).then(res => {
        this.success({
			message: '已发送验证码',
			duration:1000
        })
        this.countDown()
      })
    },
    // 点击登录
    onLogin() {
      this.checkParams('phone')
      if (this.rulesText.phone) {
        return
      }

      this.checkParams('code')
      if (this.rulesText.code) {
        return
      }

      const { params } = this

      this.$api.system.login(params).then(res => {
        localStorage.setItem('token', res.token)
        this.getUserInfo((groups) => {
          if( groups.length == 1 ){
            this.goSystem();
          }else{
            this.navCurIndex = 3
          }
        })
      })
    },
    // 点击绑定
    bindCommit() {
      this.checkParams('phone')
      if (this.rulesText.phone) {
        return
      }

      this.checkParams('code')
      if (this.rulesText.code) {
        return
      }

      const { params } = this
      const { code } = this.$route.query

      this.$api.system.bindWx({
        ...params,
        wxCode: code
      }).then(res => {
        localStorage.setItem('token', res.token)
        this.getUserInfo((groups) => {
          if( groups.length == 1 ){
            this.goSystem();
          }else{
            this.navCurIndex = 3
          }
        })
      }).catch(error => {
        if (error.message.indexOf('2002') > -1) {
          this.rulesText.bind = '此手机未开通账户'
        }
      })
    },
    // 获取个人信息
    getUserInfo(callback) {
      this.$api.system.getUserInfo().then(res => {
        this.SETUSERINFO(res)
        let cur;
        if( res.groups.length == 1 ){
          cur = res.groups
        }else{
          cur = res.groups.filter(v => v.on)
        }
        this.curTeam = cur && cur.length ? cur[0].groupId : null
        this.SETCUR(this.curTeam)

        if( res.realAuth !== 3 ){
          this.SETAUTH(false);
        }

        callback && callback(res.groups)
      })
    },
    goSystem() {
      const { curTeam } = this

      if (!curTeam) {
        this.error({
          message: '请选择所需要进入的小组'
        })
        return
      }

      this.$api.system.chooseGroup({
        groupId: curTeam
      }).then(res => {
        this.SETCUR(curTeam)

        if (this.userInfo.username === this.userInfo.phone ) {
          this.success({
            message: '登录成功, 请先完善个人信息',
			duration:1000
          })
          this.$router.push('/personal')
        } else {
          this.success({
            message: '登录成功',
			duration:1000
          })
          this.$router.push('/')
        }
      })
    },
    wxLogin() {
      const { navCurIndex } = this
      const { code } = this.$route.query

      if (code && navCurIndex === 1) {
        this.$api.system.wxLogin({
          code
        }).then(res => {
          localStorage.setItem('token', res.token)
          this.getUserInfo((groups) => {
            if( groups.length == 1 ){
              this.goSystem();
            }else{
              this.navCurIndex = 3
            }
          })
        }).catch(error => {
          if (error.message.indexOf('2001') > -1) {
            this.navCurIndex = 2
          }
        })
      }
    }
  },
  created() {
    let { query: { cur = 0 } } = this.$route

    cur = cur > 1 ? 0 : cur

    this.navCurIndex = Number(cur)
    this.wxLogin()
  },
  destroyed() {
    clearInterval(this.countTimer)
  }
}
</script>

<style lang="scss" scoped>
  .login-container {
    width: 100%;
    min-height: 800px;
    // height: 100%;
    background: url('~@/assets/image/login/login_bg.png') no-repeat;
    background-size: cover;
    .left {
      width: 550px;
      height: 150px;
      margin-right: 186px;
      background: url('~@/assets/image/login/login_img_logo.png') no-repeat;
      background-size: 100%;
    }
    .right {
      position: relative;
      width: 480px;
      min-height: 420px;
      padding: 47px 40px 0;
      background: #FFFFFF;
      box-shadow: 0px 20px 70px 0px rgba(179, 215, 252, 0.6), 0px 0px 15px 0px rgba(197, 224, 252, 0.4);
      border-radius: 2px;
      .nav-bar {
        display: flex;
        align-items: center;
        .nav-item {
          position: relative;
          font-size: 20px;
          line-height: 28px;
          color: $tertiary_text;
          &.active {
            font-size: 24px;
            line-height: 33px;
            color: $primary;
            font-weight: 600;
          }
          &:not(:last-child) {
            margin-right: 40px;
            &::after {
              content: '';
              position: absolute;
              width: 1px;
              height: 17px;
              background: $tertiary_text;
              top: 0;
              bottom: 0;
              right: -20px;
              transform: translateY(1px);
              margin: auto 0;
            }
          }
        }
      }
      .form-box {
        .form-item {
          position: relative;
          width: 100%;
          margin-top: 20px;
          >input {
            width: 100%;
            height: 52px;
            font-size: 18px;
            color: $primary_text;
            border-bottom: 1px solid $border;
            &:focus {
              border-color: $primary;
            }
            &::-webkit-input-placeholder { font-size: 16px; }
            &:-moz-placeholder { font-size: 16px; }
            &::-moz-placeholder { font-size: 16px; }
            &:-ms-input-placeholder { font-size: 16px; }
            &.check {
              border-color: $danger;
            }
          }
          .code-btn {
            position: absolute;
            width: 100px;
            height: 100%;
            color: $placeholder;
            top: 0;
            right: 0;
          }
        }
        .form-fd {
          position: relative;
          .check-text {
            position: absolute;
            font-size: 14px;
            color: $danger;
            left: 0;
            top: 16px;
            .icon {
              width: 16px;
              height: 16px;
              margin-right: 6px;
              background: url('~@/assets/image/login/login_tips_img_error.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      .wx-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 234px;
        margin-top: 20px;
        background: $primary_bg;
        border-radius: 4px;
        .wx-login {
          width: 100% !important;
          height: 100% !important;
          ::v-deep iframe {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .right-fd {
        position: absolute;
        width: 400px;
        right: 0;
        left: 0;
        color: $secondary_text;
        bottom: 30px;
        margin: 0 auto;
      }
      .scroll-box {
        width: 100%;
        height: 240px;
        margin-top: 30px;
        margin-bottom: 130px;
        .cell-item {
          position: relative;
          width: 100%;
          padding: 11px 15px 11px 20px;
          margin-bottom: 10px;
          background: #fff;
          border-radius: 2px;
          border: 1px solid $border;
          font-size: 16px;
          color: $primary_text;
          &.active {
            border-color: $primary;
            color: $primary;
          }
          &.lately {
            padding-right: 100px;
            border-color: $primary;
            color: $primary;
            &::before {
              content: '上次进入';
              position: absolute;
              width: 76px;
              height: 24px;
              background: rgba($color: $primary, $alpha: .1);
              border-radius: 2px;
              font-size: 14px;
              font-weight: 500;
              color: $primary;
              text-align: center;
              line-height: 24px;
              right: 15px;
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }
        }
      }
      .right-fd-scecond {
        position: absolute;
        width: 100%;
        height: 130px;
        background: #FFFFFF;
        border-radius: 2px;
        left: 0;
        bottom: 0;
        z-index: 1;
        &.shadow {
          box-shadow: 0px 20px 70px 0px rgba(179, 215, 252, 0.6), 0px 0px 15px 0px rgba(197, 224, 252, 0.4);
        }
      }
      .title {
        font-size: 24px;
        font-weight: bold;
        color: $primary;
      }
    }
  }

  /* .old-template {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .impowerBox {
    line-height: normal;
  }
  .impowerBox .wrp_code {
    font-size: 0;
  }
  .impowerBox .title {
    display: none;
  }
  .impowerBox .qrcode {
    border: none;
    width: 140px;
    height: 140px;
    margin: 0;
  }
  .impowerBox .status {
    padding-bottom: 0px;
    padding-top: 15px;
  }
  .impowerBox .status p {
    font-size: 14px;
    color: #999999;
  } */
</style>